import styled from 'styled-components';
import { Content, Overlay, Trigger, Close } from '@radix-ui/react-dialog';

export const ModalTrigger = styled(Trigger)`
  border: none;
  margin: auto;
`;

export const ModalOverlay = styled(Overlay)`
  background-color: rgba(var(--color-black), 0.7);
  position: fixed;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  z-index: 1300;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 27.8125em) {
    padding: 0;
  }
`;

export const ModalContent = styled(Content)`
  margin: auto;
  position: relative;

  @media screen and (min-width: 56.25em) {
    max-width: 140rem;
  }
`;

export const ModalClose = styled(Close)`
  position: absolute;
  border: none;
  background: none;
  display: block;
  right: 4rem;
  top: 2rem;

  @media screen and (min-width: 29.375em) {
    right: 3rem;
  }
`;

export const VisuallyHidden = styled.span`
  position: absolute;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  word-wrap: normal;
`;
