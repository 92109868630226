import { useQuery } from '@tanstack/react-query';
import { apiV1, get } from '../utils';
import ReactQueryKeys from '../keys';
import { CostBreakDown } from '~/types/cost-breakdown.type';
import useStore from '~/store';

const getUpcomingSessionsCostBreakDown = get<CostBreakDown>(
  apiV1,
  '/sessions/upcoming/cost-breakdown'
);

const useGetUpcomingSessionsCostBreakdown = () => {
  const { currentUser, isClient } = useStore((state) => ({
    currentUser: state.currentUser,
    isClient: state.isClient,
  }));

  return useQuery({
    queryKey: [
      ReactQueryKeys.GET_UPCOMING_SESSIONS_COST_BREAKDOWN,
      currentUser?.id,
    ],
    queryFn: () => getUpcomingSessionsCostBreakDown(),
    enabled: isClient,
    keepPreviousData: true,
    cacheTime: 300000, // 5min
    staleTime: 300000,
  });
};

export default useGetUpcomingSessionsCostBreakdown;
