const ReactQueryKeys = {
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  CHECK_SESSION: 'CHECK_SESSION',
  UPDATE_MY_DETAILS: 'UPDATE_MY_DETAILS',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  UPDATE_TIMEZONE: 'UPDATE_TIMEZONE',
  GET_WARDS: 'GET_WARDS',
  GET_WARD: 'GET_WARD',
  UPDATE_WARD: 'UPDATE_WARD',
  GET_MY_TUTOR_PROFILE: 'GET_MY_TUTOR_PROFILE',
  UPDATE_MY_TUTOR_PROFILE: 'UPDATE_MY_TUTOR_PROFILE',
  GET_ENGAGEMENTS: 'GET_ENGAGEMENTS',
  GET_ENGAGEMENT: 'GET_ENGAGEMENT',
  REQUEST_ENGAGEMENT_SUSPENSION: 'REQUEST_ENGAGEMENT_SUSPENSION',
  REQUEST_ENGAGEMENT_CANCELLATION: 'REQUEST_ENGAGEMENT_CANCELLATION',
  GET_TUTORING_DAYS: 'GET_TUTORING_DAYS',
  ADD_SOFT_SKILL: 'ADD_SOFT_SKILL',
  UPDATE_SOFT_SKILL: 'UPDATE_SOFT_SKILL',
  DELETE_SOFT_SKILL: 'DELETE_SOFT_SKILL',
  ADD_SPECIAL_NEED: 'ADD_SPECIAL_NEED',
  UPDATE_SPECIAL_NEED: 'UPDATE_SPECIAL_NEED',
  DELETE_SPECIAL_NEED: 'DELETE_SPECIAL_NEED',
  ADD_EDUCATION: 'ADD_EDUCATION',
  UPDATE_EDUCATION: 'UPDATE_EDUCATION',
  DELETE_EDUCATION: 'DELETE_EDUCATION',
  ADD_CERTIFICATION: 'ADD_CERTIFICATION',
  UPDATE_CERTIFICATION: 'UPDATE_CERTIFICATION',
  DELETE_CERTIFICATION: 'DELETE_CERTIFICATION',
  GET_TUTOR_SUGGESTION: 'GET_TUTOR_SUGGESTION',
  REQUEST_FOR_EXPIRATION_EXTENSION: 'REQUEST_FOR_EXPIRATION_EXTENSION',
  HIRE_TUTOR: 'HIRE_TUTOR',
  GET_OVERVIEW: 'GET_OVERVIEW',
  GET_TUTORS_TUTEES: 'GET_TUTORS_TUTEES',
  GET_CLIENTS_TUTORS: 'GET_CLIENTS_TUTORS',
  GET_ENGAGEMENT_SESSIONS: 'GET_ENGAGEMENT_SESSIONS',
  GET_UNPAGINATED_SESSIONS: 'GET_UNPAGINATED_SESSIONS',
  GET_SESSIONS: 'GET_SESSIONS',
  START_SESSION: 'START_SESSION',
  END_SESSION: 'END_SESSION',
  GET_ENGAGEMENTS_TO_RENEW: 'GET_ENGAGEMENTS_TO_RENEW',
  RENEW_ENGAGEMENT: 'RENEW_ENGAGEMENT',
  DISPUTE_SESSION: 'DISPUTE_SESSION',
  GET_SESSION_DISPUTES: 'GET_SESSION_DISPUTES',
  WITHDRAW_DISPUTE: 'WITHDRAW_DISPUTE',
  TOPUP_WALLET: 'TOPUP_WALLET',
  GET_TRANSACTIONS: 'GET_TRANSACTIONS',
  GET_MY_WALLET: 'GET_MY_WALLET',
  GET_ENGAGEMENT_AMOUNT_NEEDED: 'GET_ENGAGEMENT_AMOUNT_NEEDED',
  GET_UPCOMING_SESSIONS_COST_BREAKDOWN: 'GET_UPCOMING_SESSIONS_COST_BREAKDOWN',
};

export default ReactQueryKeys;
