import styled from 'styled-components';

export const UpcomingCostBreakDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 17.5rem);
  justify-content: space-between;

  & > div:first-of-type {
    height: 65vh;
    overflow-y: auto;
    padding: 5px 0;
  }
`;

export const UpcomingCostBreakdownWrapper = styled.div`
  width: 100%;
  align-items: center;
  padding: 1rem 2rem;
  background-color: rgba(var(--color-neutral8), 0.5);
  margin-bottom: 2rem;

  @media screen and (min-width: 27.8125em) {
    display: flex;
    justify-content: space-between;
  }

  & > div {
    p > span {
      text-transform: capitalize;
      font-weight: 700;
    }

    p:last-of-type {
      font-size: 1.2rem;
      color: rgba(var(--color-neutral5));
      margin-top: 6px;
      margin-bottom: 8px;

      @media screen and (min-width: 27.8125em) {
        margin-bottom: 0;
      }
    }
  }

  & > button {
    padding: 5px 1.5rem;
  }
`;

export const UpcomingCostBreakdownTopUpContainer = styled.div`
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  margin-top: 3rem;
  position: sticky;
  bottom: 0;

  & > p {
    text-align: center;
    font-size: 1.6rem;
  }

  & > button {
    width: 100%;
  }
`;
