import { FC, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AnimatePresence } from 'framer-motion';

import { Button, ShowView, SvgIcon } from '@gate-academy/react';

interface ISideBarProps {
  closeSideBar: () => void;
  isSideBarOpen: boolean;
}

import {
  SideBarOverlay,
  SideBarWrapper,
  SideBarLinks,
  SideBarLink,
} from './mobile-sidebar.styles';

import useStore from '~/store';

import useLogout from '~/react-query/mutations/useLogout';

import routes from '~/helpers/routes';

const SideBar: FC<ISideBarProps> = ({ isSideBarOpen, closeSideBar }) => {
  const router = useRouter();

  const currentUser = useStore((state) => state.currentUser);

  const isClient = currentUser?.role?.toLowerCase() === 'client';
  const isTutor = currentUser?.role?.toLowerCase() === 'tutor';

  const { mutate: logout } = useLogout();

  const { pathname } = router;

  useEffect(() => {
    router.events.on('routeChangeComplete', closeSideBar);
    router.events.on('routeChangeError', closeSideBar);

    return () => {
      router.events.off('routeChangeComplete', closeSideBar);
      router.events.off('routeChangeError', closeSideBar);
    };
  }, [router, closeSideBar]);

  return (
    <>
      {isSideBarOpen && <SideBarOverlay onClick={() => closeSideBar()} />}

      <AnimatePresence>
        {isSideBarOpen && (
          <SideBarWrapper
            key="sidebar"
            initial={{ opacity: 0, transform: 'translateX(30rem)' }}
            animate={{ opacity: 1, transform: 'translateX(0rem)' }}
            exit={{ opacity: 0, transform: 'translateX(30rem)' }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
          >
            <SvgIcon
              iconName="close"
              big
              fillColor="neutral5"
              onClick={() => closeSideBar()}
            />

            <SideBarLinks>
              <SideBarLink isActive={pathname === '/'}>
                <Link href={routes.home()}>Home</Link>
              </SideBarLink>

              {/* <SideBarLink isActive={pathname.startsWith(routes.reports())}>
                <Link href={routes.reports()}>Progress reports</Link>
              </SideBarLink>

              <SideBarLink isActive={pathname.startsWith(routes.checklists())}>
                <Link href={routes.checklists()}>Checklists</Link>
              </SideBarLink> */}

              <SideBarLink isActive={pathname.startsWith(routes.engagements())}>
                <Link href={routes.engagements()}>Engagements</Link>
              </SideBarLink>

              <SideBarLink isActive={pathname === routes.schedule()}>
                <Link href={routes.schedule()}>Schedule</Link>
              </SideBarLink>

              <SideBarLink
                isActive={pathname.startsWith(
                  isClient ? routes.wards() : routes.tutees()
                )}
              >
                <Link href={isClient ? routes.wards() : routes.tutees()}>
                  {isTutor ? 'Tutees' : 'Wards'}
                </Link>
              </SideBarLink>

              <ShowView when={isClient}>
                <SideBarLink isActive={pathname.startsWith(routes.tutors())}>
                  <Link href={routes.tutors()}>Tutors</Link>
                </SideBarLink>

                {/* <SideBarLink isActive={false}>
                  <a
                    href={process.env.NEXT_PUBLIC_CLIENT_HIRE_TUTOR_URL}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Request tutor
                  </a>
                </SideBarLink> */}
              </ShowView>

              <ShowView when={isTutor}>
                <SideBarLink isActive={pathname === routes.tutorProfile()}>
                  <Link href={routes.tutorProfile()}>Profile</Link>
                </SideBarLink>
              </ShowView>

              <SideBarLink isActive={pathname === routes.sessionDisputes()}>
                <Link href={routes.sessionDisputes()}>Session Disputes</Link>
              </SideBarLink>

              <SideBarLink isActive={pathname === routes.wallet()}>
                <Link href={routes.wallet()}>Wallet</Link>
              </SideBarLink>

              <SideBarLink isActive={pathname === routes.transactions()}>
                <Link href={routes.transactions()}>Transactions</Link>
              </SideBarLink>

              <SideBarLink isActive={pathname === routes.settings()}>
                <Link href={routes.settings()}>Settings</Link>
              </SideBarLink>

              <Button block br6 onClick={() => logout()}>
                Logout
              </Button>
            </SideBarLinks>
          </SideBarWrapper>
        )}
      </AnimatePresence>
    </>
  );
};

export default SideBar;
