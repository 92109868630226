import {
  Button,
  ShowView,
  SidebarDetail,
  SidebarRefActions,
  SvgIcon,
} from '@gate-academy/react';
import { formatCurrency } from '@gate-academy/shared/utils';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useRef } from 'react';

import WalletTopUpModal from '../wallet-topup-modal/wallet-topup-modal';

import routes from '~/helpers/routes';
import useGetMyWallet from '~/react-query/queries/useGetMyWallet';
import useGetUpcomingSessionsCostBreakdown from '~/react-query/queries/useGetUpcomingSessionsCostBreakdown';
import useStore from '~/store';

import {
  OverviewItem,
  OverviewItemSvgContainer,
  OverviewItemTextContainer,
} from '~/styles/homepage.styles';
import {
  UpcomingCostBreakDownContainer,
  UpcomingCostBreakdownTopUpContainer,
  UpcomingCostBreakdownWrapper,
} from './upcoming-sessions-cost-breakdown.styles';

interface UpcomingSessionsCostBreakdownProps {
  trigger?: ReactElement;
}

const UpcomingSessionsCostBreakdown = ({
  trigger,
}: UpcomingSessionsCostBreakdownProps) => {
  const costBreakDownRef = useRef<SidebarRefActions>(null);

  const router = useRouter();

  const { breakdown } = router.query;

  const { isClient, currentUser, currency } = useStore((state) => ({
    isClient: state.isClient,
    currentUser: state.currentUser,
    currency: state.currentUser?.deductibleCurrency,
  }));

  const { data: walletData } = useGetMyWallet();

  const { data: costBreakdown } = useGetUpcomingSessionsCostBreakdown();

  const clientNetBalance = walletData?.clientNetBalance ?? 0;

  const totalSessionCost = costBreakdown?.total ?? 0;

  const minTopUpAmount = totalSessionCost - clientNetBalance;

  const onCostBreakdownClick = () => {
    if (!totalSessionCost) return;

    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, breakdown: 'open' },
      },
      undefined,
      {
        scroll: false,
      }
    );
  };

  useEffect(() => {
    if (breakdown && totalSessionCost) {
      costBreakDownRef.current?.open();
    }
  }, [breakdown, totalSessionCost]);

  if (!isClient) return null;

  return (
    <>
      <SidebarDetail
        ref={costBreakDownRef}
        heading="Upcoming sessions cost breakdown"
        trigger={
          trigger ?? (
            <OverviewItem
              isClient={isClient}
              style={{ cursor: totalSessionCost ? 'pointer' : 'default' }}
            >
              <OverviewItemSvgContainer isClient>
                <SvgIcon iconName="monetization" big />
              </OverviewItemSvgContainer>

              <OverviewItemTextContainer>
                <p>
                  {formatCurrency(costBreakdown?.total ?? 0, {
                    currency: currentUser?.deductibleCurrency,
                  })}
                </p>

                <p>
                  <span>Upcoming sessions cost</span>
                  <span style={{ display: 'block' }}>this month</span>
                </p>
              </OverviewItemTextContainer>
            </OverviewItem>
          )
        }
        onOpen={onCostBreakdownClick}
      >
        {(close) => (
          <UpcomingCostBreakDownContainer>
            <div>
              {costBreakdown?.breakdown?.map((breakdown) => (
                <UpcomingCostBreakdownWrapper key={breakdown.engagementId}>
                  <div>
                    <p>
                      {' '}
                      <span>{breakdown.tutee}</span>
                      {` with `} <span>{breakdown.tutor}</span>{' '}
                    </p>

                    <p>
                      <span>{breakdown.sessionsRemaining}</span>
                      {` session${
                        breakdown.sessionsRemaining > 1 ? 's' : ''
                      } left | `}
                      <span>
                        {formatCurrency(breakdown.total, { currency })}
                      </span>{' '}
                    </p>
                  </div>

                  <Button
                    onClick={() => {
                      router.push(routes.engagement(breakdown.engagementId));
                      close();
                    }}
                  >
                    View
                  </Button>
                </UpcomingCostBreakdownWrapper>
              ))}
            </div>

            <UpcomingCostBreakdownTopUpContainer>
              <p>
                Upcoming Sessions Total:{' '}
                <strong>
                  {formatCurrency(totalSessionCost, { currency })}
                </strong>
              </p>

              <ShowView when={minTopUpAmount > 0}>
                <WalletTopUpModal amount={minTopUpAmount} />
              </ShowView>
            </UpcomingCostBreakdownTopUpContainer>
          </UpcomingCostBreakDownContainer>
        )}
      </SidebarDetail>
    </>
  );
};

export default UpcomingSessionsCostBreakdown;
